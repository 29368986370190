import React, { useState, useEffect } from 'react';
import { useFormState }    from 'react-use-form-state';
import {
  Button,
  Input,
  FormLabel,
  Textarea,
  Flex,
  Box,
  Checkbox,
  useToast
}                          from '@chakra-ui/react';

import Link from '@interness/web-core/src/components/elements/Link/Link';

const Request = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formState, { email, label, text, textarea, checkbox }] = useFormState({}, {
    withIds: true,
  });

  useEffect(() => {
    const { city, email, name, phone, street } = formState.validity;
    setFormValid(city && email && name && phone && street)
  }, [formState.validity]);

  return (<>
    <Flex flexWrap="wrap" justifyContent="space-between">
      <Box width="100%" my={5}>
        <FormLabel {...label('name')}>Name*</FormLabel>
        <Input {...text('name')} variant="filled" isRequired/>
      </Box>
      <Box width={{ base: '100%', lg: '48%' }} my={5}>
        <FormLabel {...label('email')}>E-Mail*</FormLabel>
        <Input {...email('email')} variant="filled" isRequired/>
      </Box>
      <Box width={{ base: '100%', lg: '48%' }} my={5}>
        <FormLabel {...label('phone')}>Telefon*</FormLabel>
        <Input {...text('phone')} variant="filled" isRequired/>
      </Box>
      <Box width={{ base: '100%', lg: '48%' }} my={5}>
        <FormLabel {...label('street')}>Straße und Hausnummer*</FormLabel>
        <Input {...text('street')} variant="filled" isRequired/>
      </Box>
      <Box width={{ base: '100%', lg: '48%' }} my={5}>
        <FormLabel {...label('city')}>PLZ und Ort*</FormLabel>
        <Input {...text('city')} variant="filled" isRequired/>
      </Box>
      <Box w="100%" mt={5}>
        <FormLabel {...label('comment')}>Nachricht</FormLabel>
        <Textarea {...textarea('comment')} variant="filled" rows={4}/>
      </Box>
    </Flex>
    <Box mt={5} textAlign='justify'>
      <label {...label('acceptLegal')} className="int-legal-checkbox-label">
        <Checkbox colorScheme="brand" {...checkbox('acceptLegal')}/>
        <p>Ich habe die <Link to="/datenschutz" target="_blank">Datenschutzerklärung</Link> zur
          Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch
          erhoben und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur
          Bearbeitung und Beantwortung meiner Anfrage benutzt. Mit dem Absenden des Kontaktformulars
          erkläre ich mich mit der Verarbeitung einverstanden.</p>
      </label>
    </Box>
    <Box textAlign="right">
      <Button colorScheme="brand" size="sm" onClick={() => console.log('asd')} isLoading={loading}
              isDisabled={(!formValid || loading || !formState.values.acceptLegal)} mt={10}>Absenden</Button>
    </Box>
  </>)
};

export default Request;
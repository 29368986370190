import React      from 'react';
import { Router } from '@reach/router';

import SEO from '@interness/web-core/src/components/modules/SEO/SEO';

import Overview from '../components/Overview';
import Error    from '../templates/Error';
import NotFound from '../templates/NotFound';
import Property from '../templates/Property';

const ImmoPage = (props) => {

  return (
    <>
      <SEO title="Immobilien" description="Immobilien"/>
      <Router basepath={`/immobilien`}>
        <Overview path={`/`}/>
        <Property path={`/:id`}/>
        <Error path={`/error`}/>
        <NotFound default/>
      </Router>
    </>
  );
};

export default ImmoPage;

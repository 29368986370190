import React                                        from 'react';
import ImageGallery                                 from 'react-image-gallery';
import { Img }                                      from 'react-image';
import { IconButton, Skeleton, useBreakpointValue } from '@chakra-ui/react';
import { IoIosArrowBack, IoIosArrowForward }        from 'react-icons/io';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import { endpoints } from '../../config';
import './styles.css';

const assetUrl = endpoints.api + '/assets';

const Thumbnail = ({ image }) => {
  return <Img
    src={image.thumbnail}
    alt=""
    unloader={<img src="https://placehold.jp/333/ffffff/100x100.png?text=Kein%20Bild" alt="Kein Bild"/>}
    loader={<Skeleton height="100px"/>}/>
}

const Nav = ({ direction, onClick }) => {
  const size = useBreakpointValue({ sm: 'sm', md: 'md', lg: 'lg' })
  return <IconButton
    sx={{
      position: 'absolute',
      top: '45%',
      zIndex: '2',
      right: direction === 'right' && '-50px',
      left: direction === 'left' && '-50px',
      margin: '0 20px',
      opacity: 0.4,
      ':focus': {
        opacity: 1,
      },
      '.image-gallery-slide-wrapper:hover &': {
        opacity: 1,
      },
    }}
    size={size}
    aria-label={direction === 'right' ? 'Nächtes Bild' : 'Vorheriges Bild'}
    isRound
    onClick={onClick}
    icon={direction === 'right' ? <IoIosArrowForward/> : <IoIosArrowBack/>}
  />
}

const buildImageArray = (images) => {
  const arr = [];
  images.filter(image => !!image.directus_files_id).forEach(image => {
    const struc = {
      original: `${assetUrl}/${image.directus_files_id.id}?key=detail-slideshow`,
      thumbnail: `${assetUrl}/${image.directus_files_id.id}?key=detail-thumbnail`,
    };
    arr.push(struc)
  });
  return arr;
};

const Gallery = ({ images }) => {
  const galleryOptions = {
    lazyLoad: true,
    showFullscreenButton: false,
    showPlayButton: false,
    defaultImage: 'https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild',
    onErrorImageURL: 'https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild',
    renderThumbInner: image => <Thumbnail image={image}/>,
    renderRightNav: (onClick, disabled) => <Nav onClick={onClick} disabled={disabled} direction="right"/>,
    renderLeftNav: (onClick, disabled) => <Nav onClick={onClick} disabled={disabled} direction="left"/>,
    renderItem: (p) => {
      return <Img
        src={p.original}
        alt=""
        unloader={<img src="https://placehold.jp/333/ffffff/1500x1500.png?text=Kein%20Bild" alt="Kein Bild"/>}
        loader={<Skeleton height="500px"/>}/>
    }
  };

  if (images.length > 1) {
    return <ImageGallery items={buildImageArray(images)} {...galleryOptions}/>
  } else if (images.length === 1) {
    return <Img
      src={`${assetUrl}/${images.filter(image => !!image.directus_files_id)[0].directus_files_id}?key=detail-slideshow`}
      alt=""
      unloader={<img src="https://placehold.jp/333/ffffff/1500x1500.png?text=Kein%20Bild" alt="Kein Bild"/>}
      loader={<Skeleton height="500px"/>}/>
  } else {
    return <img src="https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild" alt="Kein Bild"/>
  }
};

export default Gallery;
import React from 'react';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

import PropertyGrid from './PropertyGrid';

const Overview = () => {

  return (
    <Wrapper>
      <Spacer/>
      <Heading>Aktuelle Angebote</Heading>
      <h2 style={{ textAlign: 'center' }}>Kaufangebote</h2>
      <PropertyGrid status='published' types={['sale']}/>
      {/*<h2 style={{ textAlign: 'center' }}>Mietangebote</h2>*/}
      {/*<PropertyGrid type="rent"/>*/}
      <h2 style={{ textAlign: 'center' }}>Projektentwicklung (Baulandflächen)</h2>
      <PropertyGrid status='published' type={['project_development']}/>
      <Spacer/>
    </Wrapper>
  )
};

export default Overview;
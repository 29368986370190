import React, { useContext } from 'react';
import { useQuery }          from 'react-query';
import { FormattedDate }     from 'react-intl';
import {
  Box, Button, Center, Flex, Spinner,
  Tag,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
}                            from '@chakra-ui/react';

import Wrapper    from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer     from '@interness/web-core/src/components/structure/Spacer/Spacer';
import LeafletMap from '@interness/web-core/src/components/media/LeafletMap/LeafletMap';
import SEO        from '@interness/web-core/src/components/modules/SEO/SEO';
import Link       from '@interness/web-core/src/components/elements/Link/Link';

import { IApiContext } from '../context/IApi';
import Error           from '../components/Error';
import Gallery         from '../components/Gallery/Gallery';
import PriceTag        from '../components/PriceTag';
import Request         from '../components/Request';
import { endpoints }   from '../config';

const Property = (props) => {
  const assetUrl = endpoints.api + '/assets';
  const { fetchProperty } = useContext(IApiContext);
  const { isLoading, isError, data, error } = useQuery(['property', {
    id: props.id
  }], fetchProperty, {
    refetchOnWindowFocus: false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });

  if (isError || error) {
    return <Error>Fehler beim Laden. Bitte versuchen Sie es später erneut.</Error>
  }

  if (isLoading) {
    return (
      <Wrapper>
        <Spacer/>
        <Center><Spinner/></Center>
        <Spacer/>
      </Wrapper>
    )
  }

  if (data) {
    console.log(data);
    return (
      <>
        <SEO title={data.display_name}/>
        <Spacer height={30}/>
        <Wrapper>
          <Button variant="ghost" colorScheme="brand" onClick={() => window.history.back()}>Zurück</Button>
        </Wrapper>
        <Spacer height={30}/>
        <Flex flexWrap="wrap" justifyContent="space-between" maxW="1600px" margin="auto">
          <Box w="100%" px={16}>
            <Heading style={{ textTransform: 'uppercase' }} size="xl">{data.display_name}</Heading>
          </Box>
          <Box width={{ base: '100%', lg: '50%' }} p={16} bg="base.100" sx={{
            img: {
              margin: 'auto'
            }
          }}>
            <Gallery images={data.images}/>
          </Box>
          <Box width={{ base: '100%', lg: '50%' }} p={16}>
            {data.tags && data.tags.map(tag => <Tag key={tag} mx={2} mb={2}>{tag}</Tag>)}
            <Tabs mt={4}>
              <TabList>
                <Tab><Heading as="h3" size="sm">Beschreibung</Heading></Tab>
                <Tab><Heading as="h3" size="sm">Details</Heading></Tab>
                {data.pdf_attachments.length > 0 && <Tab><Heading as="h3" size="sm">Anhänge</Heading></Tab>}
                <Tab><Heading as="h3" size="sm">Anfragen</Heading></Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <div dangerouslySetInnerHTML={{ __html: data.description }}/>
                </TabPanel>
                <TabPanel>
                  <Table>
                    <Tbody>
                      {data.internal_id && <Tr>
                        <Td><b>Angebotsnummer</b></Td>
                        <Td>{data.internal_id}</Td>
                      </Tr>}
                      {data.city && data.street && data.zip && <Tr>
                        <Td><b>Adresse</b></Td>
                        <Td>{data.street}, {data.city} {data.zip}</Td>
                      </Tr>}
                      {data.purchase_price_brutto && <Tr>
                        <Td><b>Kaufpreis</b></Td>
                        <Td><PriceTag price={data.purchase_price_brutto}/></Td>
                      </Tr>}
                      {data.courtage && <Tr>
                        <Td><b>Maklercourtage*</b></Td>
                        <Td>{data.courtage}</Td>
                      </Tr>}
                      {data.cold_rent_brutto && <Tr>
                        <Td><b>Kaltmiete</b></Td>
                        <Td><PriceTag price={data.cold_rent_brutto}/></Td>
                      </Tr>}
                      {data.extra_cost_brutto && <Tr>
                        <Td><b>Nebenkosten</b></Td>
                        <Td><PriceTag price={data.extra_cost_brutto}/></Td>
                      </Tr>}
                      {data.heating_cost_brutto && <Tr>
                        <Td><b>Heizkosten</b></Td>
                        <Td><PriceTag price={data.heating_cost_brutto}/></Td>
                      </Tr>}
                      {data.deposit && <Tr>
                        <Td><b>Kaution</b></Td>
                        <Td><PriceTag price={data.deposit}/></Td>
                      </Tr>}
                      {data.availability && <Tr>
                        <Td><b>Verfügbar ab</b></Td>
                        <Td><FormattedDate value={data.availability}
                                           hour12={false}
                                           year="numeric"
                                           month="long"
                                           day="2-digit"/></Td>
                      </Tr>}
                      {data.condition && <Tr>
                        <Td><b>Zustand</b></Td>
                        <Td>{data.condition}</Td>
                      </Tr>}
                      {data.condition && <Tr>
                        <Td><b>Qualität der Ausstattung</b></Td>
                        <Td>{data.quality}</Td>
                      </Tr>}
                      {data.construction_year && <Tr>
                        <Td><b>Baujahr</b></Td>
                        <Td>{data.construction_year}</Td>
                      </Tr>}
                      {data.renovation_year && <Tr>
                        <Td><b>Letzte Renovierung</b></Td>
                        <Td>{data.renovation_year}</Td>
                      </Tr>}
                      {data.custom_details.map(detail => <Tr key={detail.key.display_name}>
                        <Td><b>{detail.key.display_name}</b></Td>
                        <Td>{detail.value}</Td>
                      </Tr>)}
                    </Tbody>
                  </Table>
                  {data.courtage && <p>*Maklercourtage ist fällig nach Unterzeichnung des notariellen Kaufvertrags.</p>}
                </TabPanel>
                {data.pdf_attachments.length > 0 &&
                  <TabPanel>
                    {data.pdf_attachments.map(attachment => {
                      return (
                        <div>
                          <Link to={`${assetUrl}/${attachment.directus_files_id.filename_disk}`}
                                external>{attachment.directus_files_id.filename_download}</Link>
                        </div>
                      )
                    })}
                  </TabPanel>
                }
                <TabPanel>
                  <Request/>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
        {data.street && data.city && data.zip && data.country && <LeafletMap address={{
          street: data.street,
          city: data.city,
          zip_code: data.zip,
          country: data.country,
        }}/>}
      </>
    )
  }
};

export default Property;